import { FormattedMessage, useIntl } from "react-intl"
import { SearchOutlined } from "@ant-design/icons"
import { Menu } from "antd"
import React, { Suspense, lazy } from "react"
import PropTypes from "prop-types"
import { take } from "lodash"
import { useSelector } from "react-redux"
import classNames from "classnames"

import { DesktopBreakpoint } from "../../breakpoints"
import { ExplorerSkeleton } from "../../skeletons"
import LocaleLink from "../../LocaleLink"
import { OPF_ITEM_TYPES, TREES } from "../../../globals"
import routes from "../../../routes"
import selectors from "src/store/selectors"

import "./style.less"
import { useActivePath, useIcon } from "./navigation-hooks"

const { CONFIGURATOR } = OPF_ITEM_TYPES
const Explorer = lazy(() => import("../Explorer"), ExplorerSkeleton)
const ExplorerTree = lazy(() => import("../ExplorerTree"), ExplorerSkeleton)

Navigation.propTypes = {
  /** determines whether or not the component is rendering using tabs instead of a traditional top menu */
  tabs: PropTypes.bool,
}

export default function Navigation({ tabs, className }) {
  const isActive = useActivePath()
  const getIcon = useIcon()

  const intl = useIntl()

  const userHasAccessToConfigurator = useSelector(
    selectors.userSelectors.getUserAccessToConfigurator
  )

  const userHasAccessToDrafts = useSelector(selectors.userSelectors.getUserAccessToDrafts)

  const policiesStandardsFlatTreeData = useSelector(
    selectors.documentsSelectors.getDocumentsForTree
  )(TREES.POLICIES_STANDARDS)

  const commercialLegalFlatTreeData = useSelector(selectors.documentsSelectors.getDocumentsForTree)(
    TREES.COMMERCIAL_LEGAL
  )

  const menuRoutes = Object.values(routes)
    .filter((route) => route.showInMenu)
    .filter((route) => (tabs && !route.hideInHorizontalMenu) || !tabs)
    .filter((route) => {
      if (route.access) {
        return route.access.reduce((access, type) => {
          if (access === false) return access
          switch (type) {
            case CONFIGURATOR:
              return userHasAccessToConfigurator
            case "DRAFTS":
              return userHasAccessToDrafts
            default:
              return access
          }
        }, true)
      }
      return true
    })
  const activeMenuItem = menuRoutes.find((item) => isActive(item.path)) || ""

  const menuItems = menuRoutes.map((route) => ({
    key: route.path,
    label: (
      <>
        {route.pathUrl == null ? 
        (<LocaleLink
          title={intl.formatMessage({ id: route.title })}
          navLink
          to={route.path}
          isActive={() => isActive(route.path)}
        >
          {getIcon(route)}
          <span>
            <FormattedMessage
              id={route.title}
              values={{ one: () => "", plural: (chunk) => chunk }}
            />
          </span>
        </LocaleLink>) : (
           <a 
            title={intl.formatMessage({ id: route.title })} 
            target="_blank" rel="noopener noreferrer" 
            href={intl.formatMessage({ id: route.pathUrl })} >
            {getIcon(route)}
            <span>
              <FormattedMessage
                id={route.title}
                values={{ one: () => "", plural: (chunk) => chunk }}
              />
            </span>
           </a>
        )

        }
        

        {route.path.includes(TREES.PROCESSES) && isActive(route.path) ? (
          <DesktopBreakpoint>
            <Suspense fallback={<ExplorerSkeleton />}>
              <Explorer />
            </Suspense>
          </DesktopBreakpoint>
        ) : null}

        {route.path.includes(TREES.POLICIES_STANDARDS) && isActive(route.path) ? (
          <DesktopBreakpoint>
            <Suspense fallback={<ExplorerSkeleton />}>
              <ExplorerTree flatTreeData={policiesStandardsFlatTreeData} />
            </Suspense>
          </DesktopBreakpoint>
        ) : null}

        {route.path.includes(TREES.COMMERCIAL_LEGAL) && isActive(route.path) ? (
          <DesktopBreakpoint>
            <Suspense fallback={<ExplorerSkeleton />}>
              <ExplorerTree flatTreeData={commercialLegalFlatTreeData} />
            </Suspense>
          </DesktopBreakpoint>
        ) : null}
      </>
    ),
  }))

  let primaryMenuItems = take(menuItems, 2)

  const searchMenuItem = {
    key: routes.search.path,
    label: (
      <LocaleLink
        title={intl.formatMessage({ id: routes.search.title })}
        navLink
        to={routes.search.path}
        isActive={() => isActive(routes.search.path)}
      >
        <SearchOutlined />
        <span>
          <FormattedMessage
            id={routes.search.title}
            values={{ one: () => "", plural: (chunk) => chunk }}
          />
        </span>
      </LocaleLink>
    ),
  }

  if (tabs) {
    if (menuItems.length > 4) {
      primaryMenuItems.push(searchMenuItem)
      // primaryMenuItems.push(moreMenuItem) // TODO: azure devops task #13827
    } else {
      primaryMenuItems = menuItems
    }

    return (
      <ul className={classNames("mobile-menu", className)} role="menu">
        {primaryMenuItems.map((primaryMenuItem) => (
          <li key={primaryMenuItem.key} role="menuitem" tabIndex="-1" className="mobile-menu__item">
            {primaryMenuItem.label}
          </li>
        ))}
      </ul>
    )
  }

  const menuOptions = {
    theme: "light",
    selectedKeys: [activeMenuItem.path],
    mode: "vertical",
  }

  return <Menu {...menuOptions} className={classNames("menu", className)} items={menuItems} />
}
