import { Empty } from "antd"
import { FormattedMessage } from "react-intl"
import React, { useEffect, useState } from "react"

import { Switch } from "antd"

import actions from "src/store/actions"
import FormattedTitle from "src/components/FormattedTitle"
import Loading from "src/components/Loading"
import PageTitle from "src/components/PageTitle"
import Placeholder from "src/inputs/Placeholder"

import "./style.less"
import useAction from "src/hooks/useAction"
import { Draft } from "src/types/global"
import { useSelector } from "react-redux"
import selectors from "src/store/selectors"
import { DraftsSlice } from "src/store/drafts/reducer"
import DraftRow from "./DraftRow"

export default function Drafts() {
  const [drafts, setDrafts] = useState<Draft[]>([])
  const [showAllDrafts, setShowAllDrafts] = useState(false)

  const draftState: DraftsSlice = useSelector(selectors.draftsSelectors.getOpenDraftsState)
  const currentUser: { upn: string } = useSelector(selectors.userSelectors.getUser)
  const fetchMyDrafts = useAction(actions.draftActions.fetchMyDrafts)

  const toggleShowAll = () => setShowAllDrafts((prev) => !prev)

  useEffect(() => fetchMyDrafts(), [fetchMyDrafts])
  useEffect(() => {
    setDrafts(
      showAllDrafts
        ? draftState.openDrafts
        : draftState.openDrafts.filter((draft) => draft.lastUpdatedBy === currentUser.upn)
    )
  }, [showAllDrafts, draftState, currentUser])

  return (
    <div id="drafts-page" className="page drafts fade-in">
      <PageTitle title="route.drafts.title" raw={false} values={[]} />
      <header className="title">
        <h1>
          <FormattedTitle id="route.drafts.title" />
        </h1>
        <div className="view__switch">
          <Switch
            onClick={toggleShowAll}
            checkedChildren={<FormattedMessage id="draft.allDrafts" />}
            unCheckedChildren={<FormattedMessage id="draft.myDrafts" />}
            id="view"
            checked={showAllDrafts}
            disabled={!draftState.draftsLoaded}
          />
        </div>
      </header>
      {!draftState.draftsLoaded ? (
        <Placeholder className="placeholder--medium">
          <Loading />
        </Placeholder>
      ) : !drafts.length ? (
        <Placeholder className="placeholder--medium">
          <Empty description={<FormattedMessage id="route.drafts.noResults" />} />
        </Placeholder>
      ) : (
        <div className="drafts__list">
          {drafts.map((item) => (
            <DraftRow item={item} key={item.id} />
          ))}
        </div>
      )}
    </div>
  )
}
