import { useState, useMemo } from "react"
import { Tree } from "antd"
import { useSelector } from "react-redux"
import unflattenTree from "src/store/utils/unflattenTree"
import { getCurrentSlug } from "src/store/utils/routeUtils"
import { useHistory } from "react-router-dom"
import selectors from "src/store/selectors"
import { OPF_DOCUMENT_TYPES } from "src/globals"
import { ReactComponent as TreeNodeStandard } from "src/assets/images/treenode-standard.svg"
import { ReactComponent as TreeNodePolicy } from "src/assets/images/treenode-policy.svg"

import "./style.less"

const { POLICY, STANDARD } = OPF_DOCUMENT_TYPES

export const enrichData = (flatTreeData, activeSlugs) => {
  return flatTreeData.map((node) => {
    const enrichedNode = { ...node }
    enrichedNode.key = node.slug
    enrichedNode.className = activeSlugs.includes(node.slug) ? "active" : ""
    return enrichedNode
  })
}

export function useHooks(props) {
  const [expandedKeys, setExpandedKeys] = useState([])
  const history = useHistory()
  const currentLanguage = useSelector(selectors.localeSelectors.getCurrentLanguage)
  const defaultLanguage = useSelector(selectors.localeSelectors.getDefaultLanguage)
  const currentSlug = getCurrentSlug(currentLanguage)
  const activeSlugs = useMemo(() => {
    const slugs = []
    const elements = []

    for (let slugElement of currentSlug.split("/")) {
      elements.push(slugElement)
      const activeSlug = elements.join("/")
      slugs.push(activeSlug)
    }

    return slugs
  }, [window.location.pathname])

  return {
    ...props,
    expandedKeys,
    setExpandedKeys,
    history,
    currentLanguage,
    defaultLanguage,
    activeSlugs,
    currentSlug,
  }
}

export function withActions(props) {
  const { setExpandedKeys, expandedKeys, currentLanguage, defaultLanguage, history } = props

  const handleSelect = (_selectedKeys, { node }) => {
    // Expand on select
    if (!node.isLeaf) {
      setExpandedKeys(
        node.expanded ? expandedKeys.filter((k) => k !== node.key) : expandedKeys.concat(node.key)
      )
    }

    const languagePrefix = currentLanguage !== defaultLanguage ? currentLanguage + "/" : ""
    const { slug } = node
    return history.push(`/${languagePrefix}${slug}`)
  }

  const handleExpand = (expandedKeys, { expanded }) => {
    setExpandedKeys(expandedKeys)
  }
  return {
    ...props,
    handleSelect,
    handleExpand,
  }
}

export function render(props) {
  const { expandedKeys, handleSelect, handleExpand, activeSlugs } = props
  const { flatTreeData } = props
  const treeData = unflattenTree(enrichData(flatTreeData, activeSlugs))

  return (
    <div className="explorer-tree fade-in">
      <Tree
        showLine={{
          showLeafIcon: (props) =>
            props.type === POLICY ? (
              <TreeNodePolicy />
            ) : props.type === STANDARD ? (
              <TreeNodeStandard />
            ) : (
              true
            ),
        }}
        multiple
        treeData={treeData}
        expandedKeys={expandedKeys}
        selectedKeys={activeSlugs}
        onSelect={handleSelect}
        onExpand={handleExpand}
      />
    </div>
  )
}

export default function ExplorerTree(props) {
  return render(withActions(useHooks(props)))
}
