import { Button } from "antd"
import { ResizableBox } from "react-resizable"
import { Scrollbars } from "react-custom-scrollbars"
import { useSelector } from "react-redux"
import classNames from "classnames"
import React, { useCallback } from "react"

import useActions from "src/hooks/useActions"
import useWindowHeight from "src/hooks/useWindowHeight"
import Navigation from "src/components/layout/Navigation"

import actions from "src/store/actions"
import selectors from "src/store/selectors"

import "./style.less"
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"

const minMenuWidth = 80
const resizableSettings = {
  minConstraints: [minMenuWidth, Infinity],
  maxConstraints: [420, Infinity],
  axis: "x",
  draggableOpts: { grid: [20, 20] },
}

export default function Sidebar({ routes, className }) {
  const menuSize = useSelector(selectors.uiSelectors.getMenuSize)
  const refinerEnabled = useSelector(selectors.refinerSelectors.getRefinerEnabled)
  const [resizeMenu] = useActions([actions.uiActions.userResizeMenu])
  const height = useWindowHeight() - 60
  const smallMode = menuSize <= minMenuWidth

  const onResize = useCallback(
    (e, data) => {
      const { width } = data.size
      if (width !== menuSize) resizeMenu(width)
    },
    [menuSize, resizeMenu]
  )

  const onResizeStop = useCallback(
    (e, data) => {
      const { width } = data.size
      if (width <= 200 && menuSize !== minMenuWidth) {
        window.requestAnimationFrame(() => resizeMenu(minMenuWidth))
      }
    },
    [menuSize, resizeMenu]
  )

  return (
    <div
      className={classNames(
        "sidebar",
        {
          "refiner-is-open": refinerEnabled,
          "sidebar--small-mode": smallMode,
        },
        className
      )}
    >
      <ResizableBox
        {...resizableSettings}
        width={menuSize}
        height={height}
        handleSize={[20, 20]}
        onResize={onResize}
        onResizeStop={onResizeStop}
      >
        <>
          <Scrollbars autoHide hideTracksWhenNotNeeded className="sidebar__scrollarea">
            <Navigation routes={routes} />
          </Scrollbars>
          <div className="sidebar__toggler">
            <Button
              shape="circle"
              icon={smallMode ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
              onClick={() => resizeMenu(smallMode ? 275 : minMenuWidth)}
            />
          </div>
        </>
      </ResizableBox>
    </div>
  )
}
