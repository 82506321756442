import { AutoComplete, Input } from "antd"
import { Component } from "react"
import { capitalize, debounce } from "lodash"
import PropTypes from "prop-types"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"

import { bindActions, bindSelectors } from "src/store/utils"
import actions from "src/store/actions"
import { getTypeIntlKey } from "../../../services/itemTypes"
import selectors from "src/store/selectors"
import { withRouter } from "react-router-dom"

import "./style.less"

const { Option, OptGroup } = AutoComplete

class Search extends Component {
  debounceSearch = debounce(
    (value) => {
      if (value.length < 3) return this.props.clearSearchResults()

      this.props.performSearch({
        searchContext: {
          searchParameters: value,
        },
      })
    },
    500,
    { maxWait: 700 }
  )

  handleOnSearch = (value) =>
    this.props.history.push(this.props.createLocalePath(`search?query=${value}`))

  handleChange = (value) => {
    const { setSearchText } = this.props
    setSearchText(value)
    this.debounceSearch(value)
  }

  handleSelect = (value) => this.props.history.push(this.props.createLocalePath(value))

  render() {
    const { intl, searchResults } = this.props
    const placeholder = intl.formatMessage({ id: "common.search" })
    const itemsGroupedByType = searchResults.reduce((accum, item) => {
      const itemType = accum.filter((itemType) => itemType.type === item.type)
      if (itemType.length) {
        itemType[0].children.push(item)
      } else {
        accum.push({
          type: item.type,
          children: [item],
        })
      }
      return accum
    }, [])

    const options =
      itemsGroupedByType.map((itemType) => {
        const type = intl.formatMessage(
          { id: getTypeIntlKey(itemType.type) },
          { one: (chunk) => chunk, plural: () => "" }
        )
        return (
          <OptGroup key={itemType.type} label={capitalize(type)}>
            {itemType.children.map((item) => (
              <Option
                className="search__option"
                key={item.opfId}
                value={item.slug}
                title={item.title}
              >
                {item.title}
              </Option>
            ))}
          </OptGroup>
        )
      }) || []

    return (
      <div className="search">
        <AutoComplete
          optionLabelProp=" "
          getPopupContainer={() => document.getElementsByClassName("header")[0]}
          className="search__autocomplete"
          defaultActiveFirstOption={false}
          dataSource={options}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          <Input.Search
            className="search__input"
            placeholder={placeholder}
            onSearch={this.handleOnSearch}
          />
        </AutoComplete>
      </div>
    )
  }
}

Search.propTypes = {
  searchText: PropTypes.string,
  searchResults: PropTypes.arrayOf(PropTypes.any),
  setSearchText: PropTypes.func,
  performSearch: PropTypes.func,
  clearSearchResults: PropTypes.func,
}
const mapStateToProps = bindSelectors({
  searchText: selectors.uiSelectors.getSearchText,
  searchResults: selectors.uiSelectors.getSearchResults,
})
const mapDispatchToProps = bindActions({
  setSearchText: actions.uiActions.setSearchText,
  performSearch: actions.uiActions.search,
  clearSearchResults: actions.uiActions.clearSearchResults,
})
const IntlSearch = injectIntl(withRouter(Search))
export default connect(mapStateToProps, mapDispatchToProps)(IntlSearch)
