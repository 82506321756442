import {
  AppstoreFilled,
  AppstoreOutlined,
  FileProtectOutlined,
  BankFilled,
  BankOutlined,
  ClockCircleFilled,
  ClockCircleOutlined,
  ExceptionOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  SearchOutlined,
  SettingFilled,
  SettingOutlined,
  StarFilled,
  StarOutlined,
  EditFilled,
  EditOutlined,
  CommentOutlined,
  OrderedListOutlined,
} from "@ant-design/icons"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import selectors from "src/store/selectors"

export const useActivePath = () => {
  const location = useLocation()
  const defaultLanguage = useSelector(selectors.localeSelectors.getDefaultLanguage)
  const currentLanguage = useSelector(selectors.localeSelectors.getCurrentLanguage)

  return (path: string) => {
    if (location.pathname === "/") return false
    const pathWithLanguage =
      (currentLanguage !== defaultLanguage ? "/" + currentLanguage : "") + path
    return location.pathname.replace("en/", "").startsWith(pathWithLanguage)
  }
}

export const useIcon = () => {
  const isActive = useActivePath()

  return ({
    icon,
    path,
    hasNoIconFill,
  }: {
    icon: string
    path: string
    hasNoIconFill: boolean
  }): JSX.Element => {
    const filled = isActive(path) && !hasNoIconFill

    switch (icon) {
      case "bank":
        return filled ? <BankFilled /> : <BankOutlined />
      case "appstore":
        return filled ? <AppstoreFilled /> : <AppstoreOutlined />
      case "exception":
        return <ExceptionOutlined />
      case "clock-circle":
        return filled ? <ClockCircleFilled /> : <ClockCircleOutlined />
      case "star":
        return filled ? <StarFilled /> : <StarOutlined />
      case "setting":
        return filled ? <SettingFilled /> : <SettingOutlined />
      case "search":
        return <SearchOutlined />
      case "question-circle":
        return filled ? <QuestionCircleFilled /> : <QuestionCircleOutlined />
      case "draft":
        return filled ? <EditFilled /> : <EditOutlined />
      case "feedback":
        return <CommentOutlined />
      case "file-protect":
        return <FileProtectOutlined />
      case "ordered-list":
        return <OrderedListOutlined />
      default:
        return <></>
    }
  }
}
