import { Tree } from "antd"
import { useEffect, useState } from "react"
import { isEmpty, sortBy } from "lodash"
import { connect } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { EventDataNode } from "antd/lib/tree"

import actions from "src/store/actions"
import selectors from "src/store/selectors"
import { OpfBase } from "src/types/global"
import { bindActions, bindSelectors } from "src/store/utils"
import { trimSlug } from "src/components/helpers"
import { OPF_ITEM_TYPES } from "src/globals"
import { ExplorerSkeleton } from "src/components/skeletons"

import "./style.less"

const { PROCEDURE } = OPF_ITEM_TYPES

type ExplorerProps = {
  loadedForCurrentContext: boolean
  firstLevelItems: OpfBase[]
  getItemBySlug: (slug: string) => OpfBase
  currentLanguage: string
  defaultLanguage: string
  applicationContext:
    | {
        user: { role: string; tagA: string; tagB: string; tagC: string }
        language: string
      }
    | undefined
  fetchItemBySlug: ({
    slug,
    level,
  }: {
    slug: string
    level: number
    triggeredBy?: string
  }) => Promise<OpfBase>
  menuIsSmall: boolean
}

export function Explorer(props: ExplorerProps) {
  const {
    currentLanguage,
    defaultLanguage,
    fetchItemBySlug,
    getItemBySlug,
    loadedForCurrentContext,
    firstLevelItems,
    menuIsSmall,
    applicationContext,
  } = props

  const [levelsFetched, setLevelsFetched] = useState(false)
  const [fetching, setFetching] = useState(false)

  const history = useHistory()
  const location = useLocation()

  const activeSlugs = location.pathname
    .replace(currentLanguage + "/", "")
    .split("/")
    .map((_path, index, array) => array.slice(0, index + 1).join("/"))
    .filter((str) => !(str === "" || str === "/processes"))

  useEffect(() => {
    if (!menuIsSmall && applicationContext) fetchInitiallyExpandedItems()
  }, [menuIsSmall, applicationContext?.language, applicationContext?.user])

  useEffect(() => {
    fetchInitiallyExpandedItems()
  }, [])

  const fetchInitiallyExpandedItems = async () => {
    if (fetching || loadedForCurrentContext) return
    setFetching(true)
    const slugsToFetch = activeSlugs.map(trimSlug)
    slugsToFetch.pop() // remove last item ( is fetched by the current page container )

    const levelsFetchedForInitialExpansion = Promise.all(
      slugsToFetch.map((slug) =>
        fetchItemBySlug({
          slug,
          level: 1,
          triggeredBy: "fetchInitiallyExpandedItems",
        })
      )
    )
    return levelsFetchedForInitialExpansion.then(() => {
      setLevelsFetched(true)
      setFetching(false)
    })
  }

  const handleSelect = (
    _selectedKeys: any,
    info: { node: EventDataNode<{ key: string }> }
  ): any => {
    const selectedSlug = info.node.key
    history.push(
      "/" + (currentLanguage !== defaultLanguage ? currentLanguage + "/" : "") + selectedSlug
    )
  }

  const loadTreeData = (
    treeNode: EventDataNode<{ key: string; dataRef: { slug: string } }>
  ): Promise<any> => {
    const { slug } = treeNode.dataRef
    return fetchItemBySlug({ slug, level: 1 })
  }

  const getTreeData = (data: OpfBase[]) => {
    const sortedNodes = sortBy(data, "order")
    return sortedNodes
      .filter((item) => {
        if (item.level === 1) return isEmpty(item.opfChildren) === false
        else return true
      })
      .map((item): any => {
        const itemData = getItemBySlug(item.slug)
        const children = itemData && itemData.opfChildren ? itemData.opfChildren : item.opfChildren
        const isLeaf = item.type === PROCEDURE
        return {
          title: itemData ? itemData.title : item.title,
          key: item.slug,
          isLeaf,
          dataRef: itemData || item,
          icon: null,
          children: children ? getTreeData(children) : null,
        }
      })
  }

  if ((isEmpty(firstLevelItems) && !loadedForCurrentContext) || levelsFetched === false)
    return <ExplorerSkeleton />

  return (
    <div className="process-explorer fade-in">
      <Tree
        showLine={{ showLeafIcon: true }}
        multiple
        defaultExpandedKeys={activeSlugs.map(trimSlug)}
        selectedKeys={activeSlugs.map((keyWithSlashPrefix) => keyWithSlashPrefix.substr(1))}
        onSelect={handleSelect}
        loadData={loadTreeData}
        treeData={getTreeData(firstLevelItems)}
      />
    </div>
  )
}

const mapStateToProps = bindSelectors({
  loadedForCurrentContext: selectors.itemSelectors.getInitiallyLoadedForContext,
  firstLevelItems: selectors.itemSelectors.getFirstLevelItems,
  getItemBySlug: selectors.itemSelectors.getItemBySlug,
  currentLanguage: selectors.localeSelectors.getCurrentLanguage,
  defaultLanguage: selectors.localeSelectors.getDefaultLanguage,
  applicationContext: selectors.getApplicationContext,
  menuIsSmall: selectors.uiSelectors.getMenuIsSmall,
})
const mapDispatchToProps = bindActions({
  fetchItemBySlug: actions.itemActions.fetchItemBySlug,
})
export default connect(mapStateToProps, mapDispatchToProps)(Explorer)
