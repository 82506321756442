import { FormattedDate } from "react-intl"

import FormattedTitle from "src/components/FormattedTitle"
import LocaleLink from "src/components/LocaleLink"
import { Draft } from "src/types/global"

export type DraftRowProps = {
  item: Draft
}

export default function DraftRow({ item }: DraftRowProps) {
  const emptyValue = "-"

  return (
    <LocaleLink to={item.slug} key={item.id} className="drafts__list-item">
      <div className="">
        <div className="row drafts__list-item__slug">{item.printableSlug}</div>
        <div className="row drafts__list-item__title">
          {item.title} (<FormattedTitle id="draft.version" /> {item.version})
        </div>
        <div className="row drafts__list-item__description">
          <label>
            <strong>
              <FormattedTitle id="draft.lastUpdatedBy" />:
            </strong>
            &nbsp;
          </label>
          {item.lastUpdatedBy} (
          {item.lastUpdatedDate ? (
            <FormattedDate
              value={new Date(item.lastUpdatedDate)}
              year="numeric"
              month="long"
              day="2-digit"
            />
          ) : (
            emptyValue
          )}
          )
        </div>
        <div className="row drafts__list-item__description">
          <label>
            <strong>
              <FormattedTitle id="draft.owner" />:
            </strong>
            &nbsp;
          </label>
          {item.owner}
        </div>
      </div>
    </LocaleLink>
  )
}
