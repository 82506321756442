import React from "react"
import { SearchOutlined, CommentOutlined } from "@ant-design/icons"
import { Layout, Avatar } from "antd"

import classNames from "classnames"

import Logo from "src/components/Logo"
import LocaleLink from "src/components/LocaleLink"
import UserDropdown from "src/components/UserDropdown"
import RefinerToggle from "src/components/RefinerToggle"

import "./style.less"
import { useIntl } from "react-intl"


export default function Header({ children, className }) {
  const intl = useIntl()
  return (
    <Layout.Header
      className={classNames("header", "row middle-xs", className)}
      mode="horizontal"
      key="header"
    >
      <LocaleLink className="logo" to="/processes" title={process.env.VERSION}>
        <Logo />
      </LocaleLink>

      {children}

      <div className="header__actions">
        <RefinerToggle />

        <UserDropdown />

        <LocaleLink className="circle-button" to="/search">
          <Avatar icon={<SearchOutlined />} />
        </LocaleLink>
        
        <a 
          title={intl.formatMessage({ id: "route.feedback.title" })} 
          target="_blank" 
          rel="noopener noreferrer" className="circle-button" 
          href={intl.formatMessage({ id: "route.feedback.destination" })}>
          <Avatar icon={<CommentOutlined />} />
        </a>
      </div>
    </Layout.Header>
  )
}
