import { EllipsisOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Dropdown, Menu } from "antd"
import { memo } from "react"
import { last, without } from "lodash"
import { Link } from "react-router-dom"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"

import { languageCodeExists } from "src/services/internationalization"
import { routeTitles } from "src/routes"
import selectors from "src/store/selectors"

import { isGUID, isDocumentPage, isConfiguratorPage } from "src/components/helpers"
import { getPathnameWithoutLocale } from "src/store/utils/routeUtils"

export function Breadcrumbs() {
  const intl = useIntl()
  const currentLanguage = useSelector(selectors.localeSelectors.getCurrentLanguage)
  const defaultLanguage = useSelector(selectors.localeSelectors.getDefaultLanguage)
  const pathnameWithoutLocale = getPathnameWithoutLocale(currentLanguage)
  const getItemBySlug = useSelector(selectors.itemSelectors.getItemBySlug)
  const getDocumentBySlug = useSelector(selectors.documentsSelectors.getDocumentBySlug)

  // Determine route hierarchy
  const locationIsDocumentPage = isDocumentPage(pathnameWithoutLocale)
  const locationIsConfiguratorPage = isConfiguratorPage(pathnameWithoutLocale)

  // Dissamble path
  const pathSnippets = pathnameWithoutLocale.split("/").filter(Boolean)
  const breadcrumbListIsLong = pathSnippets.length >= 5
  const [firstCrumb, secondCrumb] = pathSnippets
  const lastCrumb = last(pathSnippets)
  const breadcrumbsToRender = breadcrumbListIsLong
    ? [firstCrumb, secondCrumb, lastCrumb]
    : pathSnippets
  const hiddenBreadcrumbs = without(pathSnippets, firstCrumb, secondCrumb, lastCrumb)

  // Split paths to render breadcrumb items
  const breadcrumbItems = breadcrumbsToRender.map((path, index) => {
    const itemPath = pathSnippets.slice(0, pathSnippets.indexOf(path) + 1)
    const [pathEnd] = itemPath.slice(-1)
    if (isGUID(pathEnd) || (locationIsConfiguratorPage && index > 0)) return null
    const url = `${itemPath.join("/")}`
    let currentBaseRoute = null
    if (index === 0) {
      const isLanguageCode = languageCodeExists(url)
      if (isLanguageCode) return null
      // get translated base route (eg. Processes, Standards & Policies, etc.)
      currentBaseRoute = routeTitles.find((route) => route.path.endsWith(path))
    }

    const item = locationIsDocumentPage ? getDocumentBySlug(url) : getItemBySlug(url)

    let title
    if (currentBaseRoute) {
      title = intl.formatMessage({ id: currentBaseRoute.title })
    } else if (item) {
      title = item.title
    } else {
      return null // Don't show anything until the title is found
    }

    const linkUrl = currentLanguage !== defaultLanguage ? currentLanguage + "/" + url : url

    if (path === lastCrumb && breadcrumbListIsLong) {
      const menu = (
        <Menu
          items={hiddenBreadcrumbs.map((breadcrumb) => {
            const itemPath = pathSnippets.slice(0, pathSnippets.indexOf(breadcrumb) + 1)
            const url = `${itemPath.join("/")}`
            const item = locationIsDocumentPage ? getDocumentBySlug(url) : getItemBySlug(url)
            const title = item ? item.title : breadcrumb
            const linkUrl = currentLanguage !== defaultLanguage ? currentLanguage + "/" + url : url

            return {
              key: url,
              label: <Link to={"/" + linkUrl}>{title}</Link>,
            }
          })}
        />
      )

      return [
        <Breadcrumb.Item key={"parents_" + url}>
          <Dropdown overlay={menu}>
            <Button icon={<EllipsisOutlined />} size="small" shape="round" />
          </Dropdown>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key={url} title={title} className="breadcrumb">
          <Link to={"/" + linkUrl}>{title}</Link>
        </Breadcrumb.Item>,
      ]
    }

    return (
      <Breadcrumb.Item key={url} title={title} className="breadcrumb">
        <Link to={"/" + linkUrl}>{title}</Link>
      </Breadcrumb.Item>
    )
  })

  return <Breadcrumb>{breadcrumbItems}</Breadcrumb>
}

export default memo(Breadcrumbs)
