export default function getAntLocale(localeCode) {
  return import(`../${localeCode}.json`).then((module) => {
    const locale = module.default

    const Pagination = {
      items_per_page: locale["antd.itemsPerPage"],
      jump_to: locale["antd.jumpTo"],
      jump_to_confirm: locale["common.confirm"],
      page: "",
      prev_page: locale["antd.prevPage"],
      next_page: locale["antd.nextPage"],
      prev_5: locale["antd.previous5Pages"],
      next_5: locale["antd.next5Pages"],
      prev_3: locale["antd.previous3Pages"],
      next_3: locale["antd.next3Pages"],
    }

    const TimePicker = {
      placeholder: "Select time",
    }

    const DatePicker = {
      lang: {
        placeholder: locale["antd.datePlaceholder"],
        rangePlaceholder: [locale["antd.startDate"], locale["antd.endDate"]],
        today: locale["antd.today"],
        now: locale["antd.now"],
        backToToday: locale["antd.datePlaceholder"],
        ok: locale["common.ok"],
        clear: locale["antd.clear"],
        month: locale["antd.month"],
        year: locale["antd.year"],
        timeSelect: locale["antd.timeSelect"],
        dateSelect: locale["antd.dateSelect"],
        weekSelect: locale["antd.weekSelect"],
        monthSelect: locale["antd.monthSelect"],
        yearSelect: locale["antd.yearSelect"],
        decadeSelect: locale["antd.decadeSelect"],
        yearFormat: "YYYY",
        dateFormat: locale["form.dateFormat"],
        dayFormat: "D",
        dateTimeFormat: `${locale["form.dateFormat"]} HH:mm:ss`,
        monthBeforeYear: true,
        previousMonth: locale["antd.previousMonth"],
        nextMonth: locale["antd.nextMonth"],
        previousYear: locale["antd.previousYear"],
        nextYear: locale["antd.nextYear"],
        previousDecade: locale["antd.previousDecade"],
        nextDecade: locale["antd.nextDecade"],
        previousCentury: locale["antd.previousCentury"],
        nextCentury: locale["antd.nextCentury"],
      },
      timePickerLocale: {
        ...TimePicker,
      },
    }

    return {
      locale: localeCode,
      ...Pagination,
      ...DatePicker,
      ...TimePicker,
      Table: {
        filterTitle: locale["antd.filterTitle"],
        filterConfirm: locale["antd.ok"],
        filterReset: locale["common.reset"],
        selectAll: locale["antd.selectAll"],
        selectInvert: locale["antd.selectInvert"],
      },
      Modal: {
        okText: locale["antd.ok"],
        cancelText: locale["common.cancel"],
        justOkText: locale["antd.ok"],
      },
      Popconfirm: {
        okText: locale["antd.ok"],
        cancelText: locale["common.cancel"],
      },
      Transfer: {
        searchPlaceholder: locale["antd.searchPlaceholder"],
        itemUnit: locale["common.item"],
        itemsUnit: locale["antd.itemsUnit"],
      },
      Upload: {
        uploading: locale["antd.uploading"],
        removeFile: locale["antd.removeFile"],
        uploadError: locale["antd.uploadError"],
        previewFile: locale["antd.previewFile"],
      },
      Empty: {
        description: locale["antd.emptyDescription"],
      },
    }
  })
}
